// https://github.com/lodash/lodash/blob/2f79053d7bc7c9c9561a30dda202b3dcd2b72b90/unescape.js

/** Used to map HTML entities to characters. */
const htmlUnescapes = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
}

/** Used to match HTML entities and HTML characters. */
const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g
const reHasEscapedHtml = RegExp(reEscapedHtml.source)

/**
 * Converts the HTML entities `&amp;`, `&lt;`, `&gt;`, `&quot;`
 * and `&#39;` in `string` to their corresponding characters.
 *
 * **Note:** No other HTML entities are unescaped. To unescape additional
 * HTML entities use a third-party library like [_he_](https://mths.be/he).
 *
 * @param str [string=''] The string to unescape.
 * @returns {string} Returns the unescaped string.
 * @example
 * unescape('fred, barney, &amp; pebbles')
 * 'fred, barney, & pebbles'
 */
export default function unescape(str: string) {
  return str && reHasEscapedHtml.test(str)
    ? str.replace(reEscapedHtml, (entity) => htmlUnescapes[entity] || "'")
    : str || ''
}
