function getPathStructure(mixedPath: string, component: string, pathPrefix?: string) {
  const slug = mixedPath
    .split('/')
    .filter((part) => part !== component && !!part)
    .join('/')
  return {
    // "/the-slug/"
    headlessSlug: slug,
    /* /pathPrefix/...slug */
    bigCommercePath: `/${[pathPrefix, slug].filter((p) => !!p).join('/')}/`,
  }
}

/**
 * Takes in a mixed path and generates necessary paths for querying data on server and creating links.
 * - Both formats are necessary for routing in getStaticPaths for headless and querying in Big Commerce in getStaticProps
 * @param mixedPath {string} '/product/some-product-name/' or '/some-product-name/',  outside /'s are optional
 * @returns {Object} { headlessSlug: 'some-product-name', bigCommercePath: '/product/some-product-name/' }
 */
export function getPathStructureForProduct(mixedPath: string) {
  return getPathStructure(mixedPath, 'product', 'product')
}
/**
 * Takes in a mixed path and generates necessary paths for querying data on server and creating links.
 * - Both formats are necessary for routing in getStaticPaths for headless and querying in Big Commerce in getStaticProps
 * @param mixedPath {string} '/catalog/some-category-name/' or '/some-name-name/',  outside /'s are optional
 * @returns {Object} { headlessSlug: 'some-category-name', bigCommercePath: '/catalog/some-category-name/' }
 */
export function getPathStructureForCategory(mixedPath: string) {
  return getPathStructure(mixedPath, 'catalog', 'catalog')
}

export function getPathStructureForBrand(mixedPath: string) {
  return getPathStructure(mixedPath, 'brands', 'brands')
}
