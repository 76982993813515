import { publicConfig } from '../publicConfig'

export function getSearchspringSiteId() {
  const siteId = publicConfig.searchspringSiteId

  if (!siteId) {
    throw new Error('Must set NEXT_PUBLIC_SEARCHSPRING_SITE_ID')
  }

  return siteId
}

/**
 * @param autoComplete - If true, we use autocomplete.json to avoid muddying up insights & reporting
 */
export function getSearchStringEndpoint(autoComplete = false) {
  return `https://${getSearchspringSiteId()}.a.searchspring.io/api/search/${
    autoComplete ? 'autocomplete' : 'search'
  }.json`
}

function constructKeyPairsQueryString(keyPairs?: Array<[string, string]> | null) {
  if (keyPairs?.length) {
    return (
      '&' +
      keyPairs
        .map(([k, v]) => {
          const [low, high] = v.split(':')
          if (low && high) {
            // range filter
            return `${k}.low=${low}&${k}.high=${high}`
          }
          return `${k}=${encodeURIComponent(v)}`
        })
        .join('&')
    )
  }
  return ''
}

export function constructSearchSpringURL({
  filters,
  query,
  originalQuery,
  resultsPerPage,
  autoComplete,
  tag,
  domain,
}: {
  filters?: Array<[string, string]> | null
  query?: string
  originalQuery?: string
  resultsPerPage?: number
  autoComplete?: boolean
  tag?: string
  domain?: string
}) {
  const url = new URL(getSearchStringEndpoint(autoComplete))

  const params: Record<string, string> = {
    // https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Search-API-Request#:~:text=resultsFormat
    resultsFormat: 'native',
    // https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Search-API-Request#:~:text=siteId%20(required)
    siteId: getSearchspringSiteId(),
  }

  if (domain) params.domain = domain

  if (query) {
    // https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Search-API-Request#:~:text=q
    params.q = query
  }

  if (originalQuery) {
    params.oq = originalQuery
  }

  if (resultsPerPage) {
    // https://searchspring.zendesk.com/hc/en-us/articles/115000441706-Sorting-Pagination#:~:text=Results%20Per%20Page
    params.resultsPerPage = resultsPerPage.toString()
  }

  if (tag) {
    params.tag = tag
  }

  url.search = new URLSearchParams(params).toString() + constructKeyPairsQueryString(filters)

  return url
}
