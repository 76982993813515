import type { ParsedUrlQuery } from 'querystring'
import { stringify } from 'querystring'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import {
  getSearchSpringPageLoadId,
  getSearchSpringSessionId,
  getSearchSpringUserId,
} from '../../../lib/clientOnly/analytics/searchspring/tracking'
import { CATEGORY_DEEP_LEVELS } from '../../../lib/constants/CATEGORY_DEEP_LEVELS'
import COMPANY_INFO from '../../../lib/constants/COMPANY_INFO'
import { constructSearchSpringURL } from '../../../lib/integrations/searchspring'
import type { BigCommerceGQLCategoryTree3LevelsDeep } from '../../../lib/serverOnly/api/bigcommerce/graphql/catalog'
import type {
  SearchSpringData,
  SearchSpringResultsData,
} from '../../../lib/serverOnly/api/searchspring/search'
import type { FlattenedGQLCategoryTreeItem } from '../../../lib/utils/categories'
import { getFlattenedGQLTree } from '../../../lib/utils/categories'
import isValidPriceRange from '../../../lib/utils/isValidPriceRange'
import unescape from '../../../lib/utils/unescape'
import { useApiQuery } from '../../useApiQuery'
import useAuthStateQuery from '../../useAuthStateQuery'
import useDebounce from '../../useDebounce'

type Option = Partial<{
  calculated_price: number
  child_sku: string
  cost_price: number
  depth: number
  height: number
  id: number
  image_url: string
  inventory_level: number
  mpn: string
  option: string
  option_id: number
  option_value_id: string
  price: number
  product_id: number
  product_option_id: number
  purchasing_disabled: boolean
  retail_price: number | null
  upc: string
  value: string
  width: number
}>

export const formatVariantData = (results: SearchSpringResultsData[]) => {
  return results.map((result) => {
    const { ss_bpn_options = '' } = result
    // an empty bpn options property is the string '[]' which has a length of 2
    const emptyBpnOptionsLength = 2

    let has_single_variant_pricing = false
    let has_valid_price_range = false
    let variant_calculated_price: string | undefined = undefined

    if (ss_bpn_options.length > emptyBpnOptionsLength) {
      // TODO: Evaluate if `&quot;` is the only possible special character present in this string.
      const expanded_variants = JSON.parse(ss_bpn_options.replace(/&quot;/g, '"'))

      if (expanded_variants.length === 1) {
        has_single_variant_pricing = true
        variant_calculated_price = expanded_variants[0].price
      } else {
        has_valid_price_range = isValidPriceRange(
          expanded_variants[0].price,
          expanded_variants[expanded_variants.length - 1].price
        )
      }
    }
    return {
      ...result,
      has_single_variant_pricing,
      variant_calculated_price,
      has_valid_price_range,
    }
  })
}

function getPossibleBreadcrumbCategories(
  categoryHierarchy: string[],
  categoryTree: BigCommerceGQLCategoryTree3LevelsDeep
) {
  const unescaped = categoryHierarchy.map((h) => h.split('&gt;').map(unescape))

  const bottomMostCategories = Object.values(
    unescaped.reduce((memo, nextHierarchy) => {
      const rootCategory = nextHierarchy[0]
      if (rootCategory) {
        const currentHierarchy = memo[rootCategory]
        if (!currentHierarchy || currentHierarchy.length < nextHierarchy.length) {
          memo[rootCategory] = nextHierarchy
        }
      }
      return memo
    }, {} as Record<string, string[]>)
  )

  const flattenedGqlCats = getFlattenedGQLTree(categoryTree)

  return flattenedGqlCats.reduce(
    (matches: FlattenedGQLCategoryTreeItem[], treeItem: FlattenedGQLCategoryTreeItem) => {
      const productCategoryMatch = bottomMostCategories.find((categoryHierarchy) => {
        // Max idx of 2 is because we currently only show 3 levels deep in breadcrumbs & category hierarchy filters
        const idx = Math.min(categoryHierarchy.length - 1, CATEGORY_DEEP_LEVELS - 1)
        return categoryHierarchy[idx] === treeItem.name
      })
      if (productCategoryMatch) {
        matches.push({
          ...treeItem,
        })
      }

      return matches
    },
    []
  )
}

/**
 * search.json expects a domain parameter used for analytics. This must be consistant across SSR & client to ensure
 * search.json is preloaded correctly (link rel="preload")
 */
function useSearchSpringDomain() {
  const router = useRouter()
  const [pathname] = router.asPath.split('?')

  // Query params that start with "cw" are special carewell query parameters added in cloudfront during
  // query parameter rewriting - these are only available server-side so must be stripped to avoid client/server
  // disparity
  const filteredQuery: ParsedUrlQuery = {}
  Object.keys(router.query).forEach((k) => {
    if (!k.startsWith('cw')) {
      filteredQuery[k] = router.query[k]
    }
  })

  return `${COMPANY_INFO.storefrontUrl}${pathname}${
    Object.keys(filteredQuery).length ? `?${stringify(filteredQuery)}` : ''
  }`
}

export default function useSearchSpringQuery({
  fallbackData,
  filters = [],
  enabled = true,
  query,
  originalQuery,
  resultsPerPage,
  keepPreviousData = true,
  autoComplete,
  tag,
  categoryTree,
}: {
  fallbackData?: SearchSpringData | null // Initial data can come from e.g. SSR or static data
  filters?: Array<[string, string]>
  enabled?: boolean
  query?: string
  originalQuery?: string
  resultsPerPage?: number
  keepPreviousData?: boolean
  autoComplete?: boolean
  tag?: string
  // Used to ensure domain parameter remains the same when server-side rendering
  // Useful in preloading searchspring url
  categoryTree: BigCommerceGQLCategoryTree3LevelsDeep
}) {
  const { data: customer } = useAuthStateQuery()

  const router = useRouter()
  const domain = useSearchSpringDomain()

  const searchSpringURL = useMemo(() => {
    return constructSearchSpringURL({
      filters,
      query,
      originalQuery,
      resultsPerPage,
      autoComplete,
      tag,
      // You might be wondering why we can't simply use window.location.href here - it's because we need both the client
      // and server url to match. The domain isn't available server side and hence we use the hard coded domain based on
      // environment
      domain,
    }).toString()
  }, [filters, query, originalQuery, resultsPerPage, autoComplete, tag, domain])

  // This can be rendered by the component using this hook to preload the search url as a performance enhancement.
  const preloadElement = (
    <Head>
      <link rel="preload" href={searchSpringURL} as="fetch" />
    </Head>
  )

  const [debouncedUrl] = useDebounce(searchSpringURL)

  const searchSpringQueryEnabled =
    Boolean(filters.length || query?.trim().length || tag?.length) && enabled

  const { data, isValidating, error } = useApiQuery<SearchSpringData>(debouncedUrl, {
    enabled: searchSpringQueryEnabled,
    parser: useCallback((data) => data, []),
    revalidateOnFocus: false,
    keepPreviousData,
    getFetchConfig: useCallback(
      () => ({
        headers: {
          'searchspring-session-id': getSearchSpringSessionId(),
          'searchspring-user-id': getSearchSpringUserId(customer?.id),
          'searchspring-page-load-id': getSearchSpringPageLoadId(),
        },
      }),
      [customer?.id]
    ),
    fallbackData: fallbackData || undefined,
  })

  const dataError = data?.status && data.message

  // hack fix to revert to base URL CLP page if SearchSpring returns an error
  useEffect(() => {
    const url = new URL(window.location.href)
    if (!isValidating && dataError && url.hash) {
      router.replace(url.pathname)
    }
  }, [dataError, isValidating, router])

  const returnedData = enabled ? data : fallbackData

  return {
    isValidating,
    error,
    preloadElement,
    data: useMemo(
      () =>
        dataError || !returnedData
          ? null
          : {
              ...returnedData,
              results: formatVariantData(returnedData.results).map((r) => {
                const possibleBreadcrumbs = r.categories_hierarchy
                  ? getPossibleBreadcrumbCategories(r.categories_hierarchy, categoryTree)
                  : []

                return {
                  ...r,
                  defaultBreadcrumbs: possibleBreadcrumbs[0],
                  options: (r.child_sku_options
                    ? JSON.parse(unescape(r.child_sku_options))
                    : null) as Option[] | null,
                }
              }),
            },
      [categoryTree, returnedData]
    ),
  }
}
export type SearchSpringFilterSummary = NonNullable<
  ReturnType<typeof useSearchSpringQuery>['data']
>['filterSummary']
export type SearchSpringFacets = NonNullable<
  ReturnType<typeof useSearchSpringQuery>['data']
>['facets']
export type SearchSpringResults = NonNullable<
  ReturnType<typeof useSearchSpringQuery>['data']
>['results']
export type SearchSpringPagination = NonNullable<
  ReturnType<typeof useSearchSpringQuery>['data']
>['pagination']
