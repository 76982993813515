import { trpc } from '../lib/utils/trpc'
import type {
  InferProcedureOutput,
  Procedures,
  ReactQueryProcedureOptions,
} from '../server/routers/_app'

export default function useCartQuery(
  input?: { sortAlphabetically?: boolean },
  options: ReactQueryProcedureOptions['cart']['getCart']['options'] = {}
) {
  return trpc.cart.getCart.useQuery(input, {
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    trpc: {
      context: {
        // Show cart state ASAP! Do not batch it with other potentially slow queries
        skipBatch: true,
      },
    },
    ...(options as any),
  })
}

export type CartQueryData = NonNullable<InferProcedureOutput<Procedures['cart']['getCart']>>
export type CartLineItem = CartQueryData['items'][number]
export type CartCoupon = CartQueryData['coupons'][number]
export type CartLineItemOption = NonNullable<CartLineItem['options'][number]>
