import { publicConfig } from '../publicConfig'

const COMPANY_INFO = {
  name: 'Carewell',
  legalName: 'Carewell Family, Inc.',
  domain: publicConfig.domain,
  storefrontUrl: `https://${publicConfig.domain}`, // DON'T USE FOR INTERNAL LINKS
  trackingUrl: publicConfig.trackingUrl,
  foundingDate: 2016,
  founders: ['Bianca Padilla', 'Jonathan Magolnick'],
  address: {
    street: '1930 Abbott Street, Suite 403',
    city: 'Charlotte',
    state: 'NC',
    zip: '28203',
    country: 'USA',
  },
  phone: {
    value: '+18006962273', // value is tied to analytics tracking, must be the same
    displayValue: '800-696-2273',
  },
  email: 'support@carewell.com',
  hours: [
    {
      startHour: 0,
      endHour: 24,
      days: [1, 2, 3, 4, 5, 6, 0],
    },
  ],
  callHours: [
    {
      startHour: 0,
      endHour: 24,
      days: [1, 2, 3, 4, 5, 6, 0],
    },
  ],
  timezone: {
    value: 'America/New_York',
    displayValue: 'ET',
  },
  socialLinks: {
    crunchbase: 'https://www.crunchbase.com/organization/carewellfamily',
    facebook: 'https://facebook.com/carewellfamily/',
    instagram: 'https://instagram.com/carewellfamily/',
    linkedin: 'https://www.linkedin.com/company/carewellfamily',
    nextdoor: 'https://nextdoor.com/pages/carewell/',
    twitter: 'https://twitter.com/carewellfamily',
    youtube: 'https://www.youtube.com/channel/UCuVhI6qXZKJ4U1NqTaics3g',
  },
  LEGACY: {
    // TODO: use static Next image instead
    logo: `https://cdn11.bigcommerce.com/s-hr7ra7xc8x/product_images/uploaded_images/logo-icon-s-tag-center.png`,
  },
}

export default COMPANY_INFO
