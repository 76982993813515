import type { RefObject } from 'react'
import { useEffect } from 'react'
import { queueTask } from '../lib/utils/queueTask'

function elementIsVisible(elem: HTMLElement | null | undefined) {
  if (!elem?.parentElement) return true

  if (
    window.getComputedStyle(elem).display === 'none' ||
    window.getComputedStyle(elem).visibility === 'hidden'
  ) {
    return false
  }

  return elementIsVisible(elem.parentElement)
}

function isAncestor(elem: HTMLElement, potentialAncestor: HTMLElement) {
  let parentElem = elem.parentElement

  while (parentElem) {
    if (potentialAncestor === parentElem) return true
    parentElem = parentElem.parentElement
  }

  return false
}

/**
 * Perform an action when "clicking away" from a dom node, e.g. to close a modal/popover
 */
export function useClickAway<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T | null | undefined>,
  open: boolean,
  onClickAway?: () => void
) {
  useEffect(() => {
    const listener = (e) => {
      if (!onClickAway) return
      const elem = ref.current

      if (
        elem &&
        document.body.contains(elem) &&
        // There is an edge case whereby the target element is removed from the DOM before the click event bubbles up
        // In this case we should avoid calling onClickAway
        document.body.contains(e.target) &&
        elementIsVisible(elem) &&
        open
      ) {
        if (elem === e.target) return
        if (isAncestor(e.target, elem)) return
        onClickAway()
      }
    }

    if (open) {
      // In React 18, the function passed to useEffect is synchronous if triggered by a discrete event such as a button click.
      // This means that the event listener is registered prior to the button click event trickling up which will cause
      // onClickAway to be called immediately.
      // By pushing the listener registration to the bottom of the run loop we can delay the event listener registration
      // until the next render has completed
      queueTask(() => {
        document.addEventListener('click', listener)
      })
    }

    return () => document.removeEventListener('click', listener)
  }, [open, onClickAway])
}
