export default function isValidPriceRange(
  lowPrice?: string | number,
  highPrice?: string | number
): boolean {
  if (lowPrice === undefined || highPrice === undefined) {
    return false
  }
  const lowPriceNumber = typeof lowPrice === 'string' ? parseFloat(lowPrice) : lowPrice
  const highPriceNumber = typeof highPrice === 'string' ? parseFloat(highPrice) : highPrice

  if (isNaN(lowPriceNumber) || isNaN(highPriceNumber)) {
    return false
  }

  return lowPriceNumber < highPriceNumber
}
