export function LegacyChevronRightIcon({ className = '' }: { className?: string }) {
  return (
    <svg className={className} viewBox="-10 0 705 1024">
      <path
        fill="currentColor"
        d="M633 510l-424 424q-11 11 -26 11t-26 -11l-95 -95q-10 -10 -10 -25t10 -26l304 -303l-304 -304q-10 -11 -10 -26t10 -25l95 -95q11 -11 26 -11t26 11l424 424q10 11 10 26t-10 25v0z"
      />
    </svg>
  )
}
